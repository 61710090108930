import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalConstants } from 'app/global-constants';
import { AgentsService } from '../agents.service';
import Swal from 'sweetalert2';
import { ConfigsService } from 'app/configs/configs.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AffectationAgenceComponent } from '../affectation-agence/affectation-agence.component';
import { EntrepriseService } from 'app/entreprises/entreprise.service';
import { RompreContratAgentComponent } from '../rompre-contrat-agent/rompre-contrat-agent.component';

@Component({
  selector: 'app-agents-list',
  templateUrl: './agents-list.component.html',
  styleUrls: ['./agents-list.component.css']
})
export class AgentsListComponent implements OnInit {
  @ViewChild('closeButton') closeButton;
  @Input() idCentre ;
  @Input() idEntreprise ;
  agents = []
  pageIndex = 0;
  pageSize = 10;
  length = 0;
  pageSizeOptions = [10, 20, 50, 100];
  links:any;
  page = 0;
  recherche = "";
  rechercheAgence = "";
  centre: any = 0;
  centreFormation: any = "";
  centresFormation = [];
  agences = [];
  allAgences = [];
  agencesAffectation = [];
  idAgent: number;
  agence: any = "";
  isLoading: boolean = false;

  constructor(private router: Router, private agentService: AgentsService,
    private agenceService: EntrepriseService,
    private dialog: MatDialog, private configService: ConfigsService) { }

  ngOnInit(): void {
    
    GlobalConstants.hasPermissionRedirection(['view_agent_list', 'view_own_agent', 'view_agent'],this.router)

    if(this.idCentre !== undefined) {
      this.centreFormation = this.idCentre;
    }

    if(this.idEntreprise !== undefined) {
      this.agence = `${this.idEntreprise}`;
    }
    
    if(this.has_permission('view_agent')){
      this.getAgences();
    }

    if(!this.has_permission('view_own_agent')){
      this.getCentreFormations()
    }

    this.getAgents(0);
  }

  getCentreFormations() {
    this.configService.getCentreFormation().subscribe(
      (reponse) => {
        this.centresFormation = reponse;
      }
    )
  }

  getAgents(event:any) {
    if (event.pageSize) {
      this.pageSize = event.pageSize;
    }
    this.agents = [];
  
    this.agentService.getAgents(event.pageIndex, this.links, this.pageSize, this.recherche,
       this.centreFormation, this.agence).subscribe(
      (response) => {
        this.length = response.total;
        this.links = response.links;
        this.agents = response.agents
      },
      (error) => GlobalConstants.errorRequest(error)
    )
  }

  getAgences() {
    this.agenceService.getEntreprises().subscribe(
      (reponse) => {
        this.agences = reponse.entreprises;
        this.allAgences = reponse.entreprises;
        this.agencesAffectation = reponse.entreprises;
      }
    )
  }

  rompreContrat(agent:any) {
    let message = `Etes-vous sûr de vouloir rempre le contrat de l'agent ${agent.prenom} ${agent.nom} avec le matricule ${agent.matricule}`
    Swal.fire({
      title: "Rompre contrat",
      text: message,
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      customClass: {
        confirmButton: "btn btn-default ",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        this.agentService.rompreContratAgent(agent.id).subscribe(
          (reponse) => {
            
            if(Object.keys(reponse).includes('succes') && Object.keys(reponse).includes('message')) {
              GlobalConstants.showNotification(reponse.message, reponse.succes ? "success" : "warning")
              if(reponse.succes) {
                this.getAgents(0); 
              }
            } else {
              GlobalConstants.showNotification("Une erreur s'est survenue", "warning")
            }
          },
    
          (err) => {
            GlobalConstants.errorRequest(err)
          }
        )
      } 
    })
    /* const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      agent: agent,
    };

    this.dialog.open(RompreContratAgentComponent, dialogConfig).afterClosed().subscribe(
      (updated) => {
        if(updated) {
          this.getAgents(0); 
        }
      }
    ); */
  }

  navigateToAddAgent() {
    this.router.navigate(['/agents/ajouter'])
  }

  navigateToRecrutementAgent() {
    this.router.navigate(['/entreprises/recrutement'])
  }

  has_permission(p){
    return GlobalConstants.has_permission(p)
  }

  isAdmin() {
    return GlobalConstants.is_admin()
  }

  showAlerte(idAgent: number, operation: boolean, action: string) {
    let message = 'Etes-vous sûr ';
    if(action === 'activation') {
      message += operation ? "d'activer " : "de desactiver "
    }

    if(action === 'suppresion') {
      message += 'de supprimer'
    }
    
    message += "cet agent"

    Swal.fire({
      title: operation ? 'Activation agent' : 'Desactivation agent',
      text: message,
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      customClass: {
        confirmButton: "btn btn-default ",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        if(action === 'activation') {
          this.toggleActiveAgent(idAgent, operation)
        }

        if(action === 'suppresion') {
          this.deleteAgent(idAgent);
        }
      } 
    })
  }

  toggleActiveAgent(idAgent: number, activer: boolean) {
    
    this.agentService.toggleActiveAgent({id: idAgent, active: activer}).subscribe(
      (reponse) => {
        if(Object.keys(reponse).includes('succes') && reponse.succes === true) {
          GlobalConstants.showNotification(`L'agent a été ${activer ? 'activé' : 'desactivé'} avec succès`, "success")
          this.getAgents(0);
        }
      },
      (error) => GlobalConstants.errorRequest(error)
    )
  }

  deleteAgent(idAgent: number) {
    this.agentService.supprimerAgent(idAgent).subscribe(
      (reponse) => {
        if(Object.keys(reponse).includes('succes') && reponse.succes === true) {
          GlobalConstants.showNotification("L'agent a été supprimé avec succès", "success")
          this.getAgents(0);
        }
      },
      (error) => GlobalConstants.errorRequest(error)
    )
  }

  filterByCentre(e) {
    this.centreFormation = e.target.value;
    this.getAgents(0)
  }

  filterByAgence(e) {
    this.getAgents(0)
  }
  

  onKeyUp(event){
    this.getAgents(0)
  }

  setIdAgent(id: number) {
    this.idAgent = id;
  }

  onRechercheAgence(e) {
    let copie = this.allAgences.filter((agence) => agence.telephone==this.rechercheAgence ||
     agence.email==this.rechercheAgence || agence.nom.search(new RegExp(`${this.rechercheAgence}`, 'i')) != -1)
     this.agencesAffectation = copie;
  }
}
