import { HttpHeaders, HttpClient } from '@angular/common/http';
import { GlobalConstants } from 'app/global-constants';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EntrepriseService {

  private headers: HttpHeaders
  constructor(private _httpClient: HttpClient){}

  baseUrl = GlobalConstants.API_URL

  getHeader(){
    this.headers = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': `Token ${GlobalConstants.getToken()}`
    })
    return this.headers
  }

  getHeaderMultipartFormData(){
    let headers = new HttpHeaders({
      // 'Content-Type':  'multipart/form-data',
      'Authorization': `Token ${GlobalConstants.getToken()}`
    })
    return headers
  }
  
  downloadFile(filename: string): Observable<Blob> {
    const url = `http://localhost:4500/#${filename}`;
    console.log(url);
    return this._httpClient.get(url, { responseType: 'blob' });
  }

  //Entreprise

  postEntreprises(body):Observable<any>{
    return this._httpClient.post(`${this.baseUrl}entreprise/register/`, body, {headers:this.getHeaderMultipartFormData()})
  }

  updateEntreprise(body, id):Observable<any>{
    return this._httpClient.put(`${this.baseUrl}entreprise/get/${id}`, body, {headers:this.getHeader()})
  }


  getEntrepriseById(id):Observable<any>{
    return this._httpClient.get(`${this.baseUrl}entreprise/get/${id}`, {headers:this.getHeader()})
  }
  
  getEntreprises(pageIndex:number = 0,links = {next:null,previous:null}, pageSize = 10,q=""):Observable<any>{
    
    if(pageIndex === 0){
      if(q ){
        return this._httpClient.get(`${this.baseUrl}entreprise/?page_size=${pageSize}&q=${q}`,{headers:this.getHeader()})
      }
      return this._httpClient.get(`${this.baseUrl}entreprise/?page_size=${pageSize}`, {headers:this.getHeader()})

    }else{
      if(links.next){
        if(q){
          return this._httpClient.get(`${this.baseUrl}entreprise/?page_size=${pageSize}&q=${q}`,{headers:this.getHeader()})
        }

        return this._httpClient.get(`${links.next}&page_size=${pageSize}`,{headers:this.getHeader()})
      
      }

      if(links.previous){
        if(q){
          return this._httpClient.get(`${this.baseUrl}entreprise/?page_size=${pageSize}&q=${q}`,{headers:this.getHeader()})
        }

        return this._httpClient.get(`${links.next}&page_size=${pageSize}`,{headers:this.getHeader()})
      }
    }
  }

  delete(id):Observable<any>{
    return this._httpClient.delete(`${this.baseUrl}entreprise/get/${id}`, {headers:this.getHeader()})
  }

  getAllEntreprises():Observable<any>{
    return this._httpClient.get(`${this.baseUrl}entreprise/all/`, {headers:this.getHeader()})
  }

  // Redevances

  getRedevanceById(id):Observable<any>{
    return this._httpClient.get(`${this.baseUrl}entreprise/redevance/get/${id}`, {headers:this.getHeader()})
  }

  getRedevances(pageIndex:number = 0,links = {next:null,previous:null}, pageSize = 10,q=""):Observable<any>{
    
    if(pageIndex === 0){
      if(q ){
        return this._httpClient.get(`${this.baseUrl}entreprise/redevance/?page_size=${pageSize}&q=${q}`,{headers:this.getHeader()})
      }
      return this._httpClient.get(`${this.baseUrl}entreprise/redevance/?page_size=${pageSize}`, {headers:this.getHeader()})

    }else{
      if(links.next){
        if(q){
          return this._httpClient.get(`${this.baseUrl}entreprise/redevance/?page_size=${pageSize}&q=${q}`,{headers:this.getHeader()})
        }

        return this._httpClient.get(`${links.next}&page_size=${pageSize}`,{headers:this.getHeader()})
      
      }

      if(links.previous){
        if(q){
          return this._httpClient.get(`${this.baseUrl}entreprise/redevance/?page_size=${pageSize}&q=${q}`,{headers:this.getHeader()})
        }

        return this._httpClient.get(`${links.next}&page_size=${pageSize}`,{headers:this.getHeader()})
      }
    }
  }

  postRedevance(body):Observable<any>{
    return this._httpClient.post(`${this.baseUrl}entreprise/redevance/`, body, {headers:this.getHeader()})
  }

  updateRedevance(body, id):Observable<any>{
    return this._httpClient.put(`${this.baseUrl}entreprise/redevance/get/${id}`, body, {headers:this.getHeader()})
  }

  deleteRedevance(id):Observable<any>{
    return this._httpClient.delete(`${this.baseUrl}entreprise/redevance/get/${id}`, {headers:this.getHeader()})
  }

  agreerEntreprise(id: number, numero: string):Observable<any>{
    let data = {numero_agreement: numero}
    return this._httpClient.post(`${this.baseUrl}entreprise/${id}/agreer/`, data, {headers:this.getHeader()})
  }

  televerserAgreement(id: number, fichier: File):Observable<any>{
    const formData = new FormData(); 
    formData.append("fichier", fichier, fichier.name);
    return this._httpClient.post(`${this.baseUrl}entreprise/${id}/televerser-agrement/`, formData, {headers:this.getHeaderMultipartFormData()})
  }

  // Statistique
  getStatistique():Observable<any>{
    return this._httpClient.get(`${this.baseUrl}entreprise/statistique/`, {headers:this.getHeader()})
  }

  recruterAgent(idAgent: number, debut: any, fin: any, typeContrat: string, contrat: File):Observable<any>{
    const formData = new FormData(); 
    formData.append("contrat", contrat, contrat.name);
    formData.append('agent', `${idAgent}`);
    formData.append('debut', debut);
    formData.append('fin', fin);
    formData.append('type_contrat', typeContrat);
    return this._httpClient.post(`${this.baseUrl}entreprise/recrutement-agent/`, formData, {headers:this.getHeaderMultipartFormData()})
  }

  
  //Demande 

  postDemande(id, description, files: any ):Observable<any>{
    const formData = new FormData();
    formData.append("activite", id)
    formData.append("description", description)
    files.forEach((element)=>{
      formData.append("idDocument", element.idDocument)
      formData.append("files", element.file, element.file.name)
    }) 
    return this._httpClient.post(`${this.baseUrl}entreprise/demande-agrement/`, formData, {headers:this.getHeaderMultipartFormData()})
  }

  updateDemande(body, id):Observable<any>{
    return this._httpClient.put(`${this.baseUrl}entreprise/demande-agrement/get/${id}`, body, {headers:this.getHeader()})
  }


  getDemandeById(id):Observable<any>{
    return this._httpClient.get(`${this.baseUrl}entreprise/demande-agrement/get/${id}`, {headers:this.getHeader()})
  }
  
  getDemandes(pageIndex:number = 0,links = {next:null,previous:null}, pageSize = 10,q=""):Observable<any>{
    
    if(pageIndex === 0){
      if(q ){
        return this._httpClient.get(`${this.baseUrl}entreprise/demande-agrement/?page_size=${pageSize}&q=${q}`,{headers:this.getHeader()})
      }
      return this._httpClient.get(`${this.baseUrl}entreprise/demande-agrement/?page_size=${pageSize}`, {headers:this.getHeader()})

    }else{
      if(links.next){
        if(q){
          return this._httpClient.get(`${this.baseUrl}entreprise/demande-agrement/?page_size=${pageSize}&q=${q}`,{headers:this.getHeader()})
        }

        return this._httpClient.get(`${links.next}&page_size=${pageSize}`,{headers:this.getHeader()})
      
      }

      if(links.previous){
        if(q){
          return this._httpClient.get(`${this.baseUrl}entreprise/demande-agrement/?page_size=${pageSize}&q=${q}`,{headers:this.getHeader()})
        }

        return this._httpClient.get(`${links.next}&page_size=${pageSize}`,{headers:this.getHeader()})
      }
    }
  }

  deleteDemande(id):Observable<any>{
    return this._httpClient.delete(`${this.baseUrl}entreprise/demande-agrement/get/${id}`, {headers:this.getHeader()})
  }

  getAllDemandes():Observable<any>{
    return this._httpClient.get(`${this.baseUrl}entreprise/demande-agrement/all/`, {headers:this.getHeader()})
  }

   //Dossier
  getDossiers():Observable<any>{
    return this._httpClient.get(`${this.baseUrl}entreprise/dossier-agrement/`,{headers:this.getHeader()})
  }

  getDossierById(id):Observable<any>{
    return this._httpClient.get(`${this.baseUrl}entreprise/dossier-agrement/get/${id}`, {headers:this.getHeader()})
  }
  
  postDossier(body):Observable<any>{
    return this._httpClient.post(`${this.baseUrl}entreprise/dossier-agrement/`,body,{headers:this.getHeader()})
  } 

  updateDossier(id, body):Observable<any>{
    return this._httpClient.put(`${this.baseUrl}entreprise/dossier-agrement/get/${id}`,body,{headers:this.getHeader()})
  }

  deleteDossier(id):Observable<any>{
    return this._httpClient.delete(`${this.baseUrl}entreprise/dossier-agrement/get/${id}`,{headers:this.getHeader()})
  }
  
}
