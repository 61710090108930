import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalConstants } from 'app/global-constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AgentsService {

  private headers: HttpHeaders
  constructor(private _httpClient: HttpClient){}

  baseUrl = GlobalConstants.API_URL

  getHeader(){
    this.headers = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': `Token ${GlobalConstants.getToken()}`
    })
    return this.headers
  }

  getHeaderMultipartFormData(){
    let headers = new HttpHeaders({
      //'Content-Type':  'multipart/form-data',
      'Authorization': `Token ${GlobalConstants.getToken()}`
    })
    return headers
  }

  getAgents(pageIndex:number = 0,links = {next:null,previous:null}, pageSize = 10,q="",
  centre=0, agence=0, recrutement=false):Observable<any>{
    
    if(pageIndex === 0){
      if(q || centre || agence){
        return this._httpClient.get(`${this.baseUrl}agent/?page_size=${pageSize}&q=${q}&centre=${centre}&agence=${agence}&recrutement=${recrutement}`,{headers:this.getHeader()})
      }
      return this._httpClient.get(`${this.baseUrl}agent/?page_size=${pageSize}&recrutement=${recrutement}`, {headers:this.getHeader()})

    }else{
      if(links.next){
        if(q || centre || agence){
          return this._httpClient.get(`${this.baseUrl}agent/?page_size=${pageSize}&q=${q}&centre=${centre}&agence=${agence}&recrutement=${recrutement}`,{headers:this.getHeader()})
        }
        
        return this._httpClient.get(`${links.next}&page_size=${pageSize}`,{headers:this.getHeader()})
      }

      if(links.previous){
        if(q || centre || agence){
          return this._httpClient.get(`${this.baseUrl}agent/?page_size=${pageSize}&q=${q}&centre=${centre}&agence=${agence}&recrutement=${recrutement}`,{headers:this.getHeader()})
        }
        
        return this._httpClient.get(`${links.previous}&page_size=${pageSize}`,{headers:this.getHeader()})
      }
    }
  }

  getAgentsRecrutement(pageIndex:number = 0,links = {next:null,previous:null}, pageSize = 10,q="",
  centre=0):Observable<any>{
    return this.getAgents(pageIndex, links, pageSize, q, centre, 0, true);
  }

  getAgent(id: number):Observable<any>{
    return this._httpClient.get(`${this.baseUrl}agent/${id}/`, {headers:this.getHeader()})
  }
  
  postAgent(agent:any):Observable<any>{
    return this._httpClient.post(`${this.baseUrl}agent/ajouter/`, agent, {headers:this.getHeaderMultipartFormData()})
  }

  updateAgent(id: number, agent:any):Observable<any>{
    return this._httpClient.put(`${this.baseUrl}agent/${id}/update/`, agent, {headers:this.getHeader()})
  }

  toggleActiveAgent(data:any):Observable<any>{
    return this._httpClient.post(`${this.baseUrl}agent/active/`, data, {headers:this.getHeader()})
  }

  supprimerAgent(id:number):Observable<any>{
    return this._httpClient.post(`${this.baseUrl}agent/${id}/supprimer`, {}, {headers:this.getHeader()})
  }

  rompreContratAgent(id:number):Observable<any>{
    return this._httpClient.post(`${this.baseUrl}agent/${id}/rompre-contrat/`, {}, {headers:this.getHeader()})
  }
}
