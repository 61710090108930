import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgentsListComponent } from './agents-list/agents-list.component';
import { RouterModule } from '@angular/router';
import { ConfigsRoute } from './agents.routing';
import { AgentEditComponent } from './agent-edit/agent-edit.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatDialogModule} from "@angular/material/dialog";
import { AffectationAgenceComponent } from './affectation-agence/affectation-agence.component';
import { RompreContratAgentComponent } from './rompre-contrat-agent/rompre-contrat-agent.component';



@NgModule({
  declarations: [
    //AgentsListComponent,
    AgentEditComponent,
    AffectationAgenceComponent,
    RompreContratAgentComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(ConfigsRoute),
    ReactiveFormsModule,
    FormsModule,
    AngularMyDatePickerModule,
    MatPaginatorModule,
    MatDialogModule
  ],

  entryComponents: [AffectationAgenceComponent]
})
export class AgentsModule { }
