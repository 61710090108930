import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AgentsService } from '../agents.service';
import { GlobalConstants } from 'app/global-constants';

@Component({
  selector: 'app-rompre-contrat-agent',
  templateUrl: './rompre-contrat-agent.component.html',
  styleUrls: ['./rompre-contrat-agent.component.css']
})
export class RompreContratAgentComponent implements OnInit {
  finContrat: any = null;
  agent: any;
  isSaving = false;
  messageErreur: "";

  constructor(private dialogRef: MatDialogRef<RompreContratAgentComponent>,
    private agentService: AgentsService,
    @Inject(MAT_DIALOG_DATA) data) { 
      this.agent = data.agent
    }

  ngOnInit(): void {
    this.dialogRef.updateSize('25%', '400px');
    console.log(this.agent)
  }

  onFinContratChange($event){
    this.finContrat = $event.target.value;
  }

  close(updated) {
    this.dialogRef.close(updated);
  }

  fermer() {
    this.close(false)
  }

  validation() {
    this.isSaving = true;
    this.messageErreur = "";
    
    this.agentService.rompreContratAgent(this.agent.id).subscribe(
      (reponse) => {
        this.isSaving = false;
        if(Object.keys(reponse).includes('succes') && Object.keys(reponse).includes('message')) {
          if(reponse.succes) {
            GlobalConstants.showNotification(reponse.message, "success")
            this.close(true)
          } else {
            this.messageErreur = reponse.message
          }
        }
        
      },

      (err) => {
        this.isSaving = false;
        GlobalConstants.errorRequest(err)
      }
    )
    
  }

  formatageDate(date: any) {
    return GlobalConstants.formatDate(date);
  }

}
