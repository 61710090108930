import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalConstants } from 'app/global-constants';
import Swal from 'sweetalert2';
import { ConfigsService } from 'app/configs/configs.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EntrepriseService } from 'app/entreprises/entreprise.service';

@Component({
  selector: 'app-list-agrement',
  templateUrl: './list-agrement.component.html',
  styleUrls: ['./list-agrement.component.css']
})
export class ListAgrementComponent implements OnInit {
  demandes = []
  pageIndex = 0;
  pageSize = 10;
  length = 0;
  pageSizeOptions = [10, 20, 50, 100];
  links:any;
  page = 0;
  recherche = "";
  p: number = 1;

  constructor(private router: Router, private entrepriseService: EntrepriseService,
    private agenceService: EntrepriseService,
    private dialog: MatDialog, private configService: ConfigsService) { }

  ngOnInit(): void {
    GlobalConstants.hasPermissionRedirection('view_demande_agrement',this.router)
    this.getDemandes(0);
  }
 

  getDemandes(event:any) {
    if (event.pageSize) {
      this.pageSize = event.pageSize;
    }
    this.demandes = [];
    
    this.entrepriseService.getDemandes(event.pageIndex, this.links, this.pageSize, this.recherche).subscribe(
      (response) => {
        this.length = response.total;
        this.links = response.links;
        this.demandes = response.demandes
      },
      (error) => GlobalConstants.errorRequest(error)
    )
  }

  navigateToAddAgrement(){
    this.router.navigate(['agrements/add'])
  }


  has_permission(p){
    return GlobalConstants.has_permission(p)
  }

  showSwalDelete(id) {
    Swal.fire({
      title: "Suppression de la demande ",
      text: "Voulez-vous vraiment supprimer cette demande ?",
      icon: "warning",
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showConfirmButton: true,
      showCancelButton: true,
      backdrop: false,
      focusCancel: true,
      confirmButtonColor: "#0A273B",
      cancelButtonColor: "#ef8157",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteDemande(id);
      } else {
        Swal.close();
      }
    });
  }


  deleteDemande(idRedevance: number) {
    this.entrepriseService.deleteDemande(idRedevance).subscribe(
      (response) => {
        if(response.status === 'success') {
          GlobalConstants.showNotification("La demande a été supprimé avec succès", "success")
          this.getDemandes(0);
        }
      },
      (error) => GlobalConstants.errorRequest(error)
    )
  }

  cancel(){
    this.router.navigate(['/agrements/list']);
  }

  is_admin(){
    return GlobalConstants.is_admin();
  }

  is_agence(){
    return GlobalConstants.is_agence();
  }

  formatDate(date){
    return GlobalConstants.formatDate(date);
  }

  onKeyUp(event){
    this.getDemandes(0)
  }
}
