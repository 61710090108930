import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularMyDatePickerDirective, IAngularMyDpOptions } from 'angular-mydatepicker';
import { AgentsService } from '../agents.service';
import { GlobalConstants } from 'app/global-constants';
import { ConfigsService } from 'app/configs/configs.service';
import intlTelInput from "intl-tel-input";

@Component({
  selector: 'app-agent-edit',
  templateUrl: './agent-edit.component.html',
  styleUrls: ['./agent-edit.component.css']
})
export class AgentEditComponent implements OnInit {

  agentForm : FormGroup;
  @ViewChild('dpNaissance') mydpDateNaissance: AngularMyDatePickerDirective;
  @ViewChild('dpIdentite') mydpIdentite: AngularMyDatePickerDirective;
  myDatePickerOptions:IAngularMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    disableSince: {year: 0, month: 0, day: 0},
  };
  centresFormation = [];
  centreIsBlur: boolean = false;
  id: number = 0;
  agent: any;
  isLoading: boolean = false;

  constructor(private router: Router, private agentService: AgentsService,
     private configService: ConfigsService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    GlobalConstants.hasPermissionRedirection('create_agent',this.router)
    this.getDetailAgent();
    this.initPhone();
    this.agentForm =  new FormGroup({
      prenom: new FormControl('', [Validators.required]),
      nom: new FormControl('', [Validators.required]),
      genre: new FormControl('', [Validators.required]),
      date_naissance: new FormControl('', [Validators.required]),
      lieu_naissance: new FormControl('', [Validators.required]),
      situation_matrimoniale: new FormControl('', [Validators.required]),
      profession: new FormControl('', [Validators.required]),
      adresse: new FormControl('', [Validators.required]),
      numero_identite: new FormControl('', [Validators.required]),
      expiration_identite: new FormControl('', [Validators.required]),
      pere: new FormControl('', [Validators.required]),
      mere: new FormControl('', [Validators.required]),
      telephone: new FormControl('', [Validators.required, Validators.pattern('^6[2165][0-9]{7}$')]),
      email: new FormControl('',[Validators.required,Validators.email]),
      centre_formation: new FormControl(''),
    })

    this.getCentreFormations()
  }

  getDetailAgent() {
    let params = this.route.snapshot.params;
    if(Object.keys(params).includes('id')) {
      this.id = params.id;
      this.agentService.getAgent(this.id).subscribe(
        (reponse) => {
          this.agent = reponse;
          
          let centre = this.agent.centre_formation === null ? "" : `${this.agent.centre_formation.id}`;

          this.agentForm.setValue({
            prenom: this.agent.prenom,
            nom: this.agent.nom,
            genre: this.agent.genre,
            date_naissance: this.agent.date_naissance,
            lieu_naissance:this.agent.lieu_naissance,
            situation_matrimoniale: this.agent.situation_matrimoniale,
            profession: this.agent.profession,
            adresse: this.agent.adresse,
            numero_identite: this.agent.numero_identite,
            expiration_identite: this.agent.expiration_identite,
            pere: this.agent.pere,
            mere: this.agent.mere,
            telephone: this.agent.telephone,
            email: this.agent.email,
            centre_formation: centre
          })
        }
      )
    }
  }

  getCentreFormations() {
    this.configService.getCentreFormation().subscribe(
      (reponse) => {
        this.centresFormation = reponse;
      }
    )
  }

  toggleCalendarDateNaissance(): void {
    this.mydpDateNaissance.toggleCalendar()
  }

  toggleCalendarIdentite(): void {
    this.mydpIdentite.toggleCalendar()
  }

  centreValid() {
    if(!this.isAdmin()) {
      return true;
    }

    let centre = this.agentForm.value['centre_formation']
    return centre !== ""
  } 

  onBlurChange($event) {
    this.centreIsBlur = true;
  }

  isAdmin() {
    return GlobalConstants.is_admin()
  }

  onSubmit($event) {
    $event.preventDefault()
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    let data = {...this.agentForm.value}
    let formData = new FormData();
    

    if(!this.isAdmin()) {
      formData.delete('centre_formation')
      //formData.append("centre_formation", this.centreFormation);
    }
    
    Object.keys(data).forEach(key => formData.append(key, data[key]));
    
    if(this.id === 0) {
      this.agentService.postAgent(formData).subscribe(
        (reponse) => {
          this.isLoading = false;

          if(Object.keys(reponse).includes('id')) {
            GlobalConstants.showNotification("L'agent a été ajouté avec succès", "success")
            if(GlobalConstants.is_admin()) {
              this.router.navigate(['/agents/liste']);
            } else {
              this.router.navigate(['/centres/mon-centre']);
            }
            
          } else {
            GlobalConstants.showNotification("Une erreur s'est produit lors de l'ajout de l'agent", "error")
          }
        },
  
        (err) => {
          this.isLoading = false;
          GlobalConstants.errorRequest(err)
        }
      )
    } else {
      this.agentService.updateAgent(this.id, data).subscribe(
        (reponse) => {
          this.isLoading = false;

          if(Object.keys(reponse).includes('id')) {
            GlobalConstants.showNotification("L'agent a été modifié avec succès", "success")
            this.router.navigate(['/agents/liste']);
          } else {
            GlobalConstants.showNotification("Une erreur s'est produit lors de la modification de l'agent", "error")
          }
        },
  
        (err) => {
          this.isLoading = false;
          GlobalConstants.errorRequest(err)
        }
      )
    }  
  }

  cancel(){
    this.router.navigate(['/agents/liste']);
  }

  formatDate(date: string) {
    let dates = date.split('/')
    return `${dates[2]}-${dates[1]}-${dates[0]}`;
  }

  initPhone() {
    const input = document.querySelector("#telephone");
    // Récupérer le champ email et sa taille
    const emailInput = document.querySelector("#email");
    const emailInputStyle = window.getComputedStyle(emailInput);
    const emailInputWidth = emailInputStyle.getPropertyValue("width");

    // Appliquer la taille au champ téléphone
    if (input instanceof HTMLInputElement) {
      input.style.width = emailInputWidth;
    }

    // Appliquer le gn au champ téléphone
    if (input) {
      const iti = intlTelInput(input, {
        initialCountry: "gn",
        separateDialCode: true,
        autoPlaceholder: "polite",
        nationalMode: false,
        formatOnDisPROFESSIONplay: false,
        customPlaceholder: function (
          selectedCountryPlaceholder,
          selectedCountryData
        ) {
          return "Ex: " + selectedCountryPlaceholder;
        },
      });
    }
  }

}
