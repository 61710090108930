import { HttpHeaders, HttpClient } from '@angular/common/http';
import { GlobalConstants } from 'app/global-constants';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigsService {

  private headers: HttpHeaders
  constructor(private _httpClient: HttpClient){}

  baseUrl = GlobalConstants.API_URL

  getHeader(){
    this.headers = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': `Token ${GlobalConstants.getToken()}`
    })
    return this.headers
  }
  
  getIdentifiant():Observable<any>{
    return this._httpClient.get(`${this.baseUrl}dossiers/identifiant-all`, {headers:this.getHeader()})
  }

  putIdentifiant(id,body):Observable<any>{
    return this._httpClient.put(`${this.baseUrl}dossiers/identifiant-update/${id}`,body,{headers:this.getHeader()})
  }

  //Journal
  postJournal(body):Observable<any>{
    return this._httpClient.post(`${this.baseUrl}dossiers/journal-add`, body, {headers:this.getHeader()})
  }
  updateJournal(body, id):Observable<any>{
    return this._httpClient.post(`${this.baseUrl}dossiers/journal-update/${id}`, body, {headers:this.getHeader()})
  }
  getJournal():Observable<any>{
    return this._httpClient.get(`${this.baseUrl}dossiers/journal-get`, {headers:this.getHeader()})
  }
  deletreJournal(id):Observable<any>{
    return this._httpClient.delete(`${this.baseUrl}dossiers/journal-delete/${id}`, {headers:this.getHeader()})
  }
  getJournalById(id):Observable<any>{
    return this._httpClient.get(`${this.baseUrl}dossiers/journal/${id}`, {headers:this.getHeader()})
  }

  //Geometre
  postGeometre(body):Observable<any>{
    return this._httpClient.post(`${this.baseUrl}dossiers/geometre-add`, body, {headers:this.getHeader()})
  }
  updateGeometre(body, id):Observable<any>{
    return this._httpClient.post(`${this.baseUrl}dossiers/geometre-update/${id}`, body, {headers:this.getHeader()})
  }
  getGeometre():Observable<any>{
    return this._httpClient.get(`${this.baseUrl}dossiers/geometre-get`, {headers:this.getHeader()})
  }
  deletreGeometre(id):Observable<any>{
    return this._httpClient.delete(`${this.baseUrl}dossiers/geometre-delete/${id}`, {headers:this.getHeader()})
  }
  getGeometreById(id):Observable<any>{
    return this._httpClient.get(`${this.baseUrl}dossiers/geometre/${id}`, {headers:this.getHeader()})
  }

 //Unite Lot
  postUniteLot(body):Observable<any>{
    return this._httpClient.post(`${this.baseUrl}dossiers/lot-add`, body, {headers:this.getHeader()})
  }
  updateUniteLot(body, id, is_delete=''):Observable<any>{
    return this._httpClient.post(`${this.baseUrl}dossiers/lot-update/${id}?delete=${is_delete}`, body, {headers:this.getHeader()})
  }
  getUniteLot():Observable<any>{
    return this._httpClient.get(`${this.baseUrl}dossiers/lot-get`, {headers:this.getHeader()})
  }
  getUniteLotById(id):Observable<any>{
    return this._httpClient.get(`${this.baseUrl}dossiers/lot/${id}`, {headers:this.getHeader()})
  }

 //Parcelle
  postParcelle(body):Observable<any>{
    return this._httpClient.post(`${this.baseUrl}dossiers/parcelle-add`, body, {headers:this.getHeader()})
  }
  updateParcelle(body, id, is_delete=''):Observable<any>{
    return this._httpClient.post(`${this.baseUrl}dossiers/parcelle-update/${id}?delete=${is_delete}`, body, {headers:this.getHeader()})
  }
  getParcelle():Observable<any>{
    return this._httpClient.get(`${this.baseUrl}dossiers/parcelle-get`, {headers:this.getHeader()})
  }
  getParcelleById(id):Observable<any>{
    return this._httpClient.get(`${this.baseUrl}dossiers/parcelle/${id}`, {headers:this.getHeader()})
  }
  deleteParcelle(id):Observable<any>{
    return this._httpClient.delete(`${this.baseUrl}dossiers/parcelle-delete/${id}`, {headers:this.getHeader()})
  }


 //Zone
  postZone(body):Observable<any>{
    return this._httpClient.post(`${this.baseUrl}dossiers/zone-add`, body, {headers:this.getHeader()})
  }
  updateZone(body, id, is_delete=''):Observable<any>{
    return this._httpClient.post(`${this.baseUrl}dossiers/zone-update/${id}?delete=${is_delete}`, body, {headers:this.getHeader()})
  }
  getZone():Observable<any>{
    return this._httpClient.get(`${this.baseUrl}dossiers/zone-get`, {headers:this.getHeader()})
  }
  getZoneById(id):Observable<any>{
    return this._httpClient.get(`${this.baseUrl}dossiers/zone/${id}`, {headers:this.getHeader()})
  }
  deleteZone(id):Observable<any>{
    return this._httpClient.delete(`${this.baseUrl}dossiers/zone-delete/${id}`, {headers:this.getHeader()})
  }

 //Volume
  postVolume(body):Observable<any>{
    return this._httpClient.post(`${this.baseUrl}dossiers/volume-add`, body, {headers:this.getHeader()})
  }
  updateVolume(body, id, is_delete=''):Observable<any>{
    return this._httpClient.post(`${this.baseUrl}dossiers/volume-update/${id}?delete=${is_delete}`, body, {headers:this.getHeader()})
  }
  getVolume():Observable<any>{
    return this._httpClient.get(`${this.baseUrl}dossiers/volume-get`, {headers:this.getHeader()})
  }
  getVolumeById(id):Observable<any>{
    return this._httpClient.get(`${this.baseUrl}dossiers/volume/${id}`, {headers:this.getHeader()})
  }
  deleteVolume(id):Observable<any>{
    return this._httpClient.delete(`${this.baseUrl}dossiers/volume-delete/${id}`, {headers:this.getHeader()})
  }


  //Documents
  getDocuments(typeEntreprise):Observable<any>{
    if(typeEntreprise){
      return this._httpClient.get(`${this.baseUrl}entreprise/documents/?typeEntreprise=${typeEntreprise}`,{headers:this.getHeader()})
    }
    return this._httpClient.get(`${this.baseUrl}entreprise/documents/`,{headers:this.getHeader()})
  }

  getDocumentsById(id):Observable<any>{
    return this._httpClient.get(`${this.baseUrl}entreprise/documents/get/${id}`, {headers:this.getHeader()})
  }
  
  postDocuments(body):Observable<any>{
    return this._httpClient.post(`${this.baseUrl}entreprise/documents/`,body,{headers:this.getHeader()})
  } 

  updateDocuments(id, body):Observable<any>{
    return this._httpClient.put(`${this.baseUrl}entreprise/documents/get/${id}`,body,{headers:this.getHeader()})
  }

  updateDocumentsActivate(id, body):Observable<any>{
    return this._httpClient.put(`${this.baseUrl}entreprise/documents/active/${id}`,body,{headers:this.getHeader()})
  }

  deleteDocuments(id):Observable<any>{
    return this._httpClient.delete(`${this.baseUrl}entreprise/documents/get/${id}`,{headers:this.getHeader()})
  }
  
  
 /*  getDocuments(category='', isRequired=false):Observable<any>{
    return this._httpClient.get(`${this.baseUrl}societe/documents-get?category=${category}&isRequired=${isRequired}`, {headers:this.getHeader()})
  } */
 
  //Region
  getRegion():Observable<any>{
    return this._httpClient.get(`${this.baseUrl}societe/regions`,{headers:this.getHeader()})
  }
  deleteRegion(id):Observable<any>{
    return this._httpClient.get(`${this.baseUrl}societe/deleteRegion/${id}`,{headers:this.getHeader()})
  }
  updateRegion(id,body):Observable<any>{
    return this._httpClient.put(`${this.baseUrl}societe/region/${id}`,body,{headers:this.getHeader()})
  }
  postRegion(body):Observable<any>{
    return this._httpClient.post(`${this.baseUrl}societe/createRegion`,body, {headers:this.getHeader()})
  }
  
  //Prefecture
  getPrefecture(region_id):Observable<any>{
    return this._httpClient.get(`${this.baseUrl}societe/prefectures/${region_id}`, {headers:this.getHeader()})
  }
  deletePrefecture(id):Observable<any>{
    return this._httpClient.get(`${this.baseUrl}societe/deletePrefecture/${id}`,{headers:this.getHeader()})
  }
  updatePrefecture(id,body):Observable<any>{
    return this._httpClient.put(`${this.baseUrl}societe/prefecture/${id}`,body,{headers:this.getHeader()})
  }
  postPrefecture(body):Observable<any>{
    return this._httpClient.post(`${this.baseUrl}societe/createPrefecture`,body, {headers:this.getHeader()})
  }
  
  //Sous-prefectures
  getSousPrefectures(prefecture_id):Observable<any>{
    return this._httpClient.get(`${this.baseUrl}societe/sousPrefectures/${prefecture_id}`, {headers:this.getHeader()})
  }
  deleteSousPrefecture(id):Observable<any>{
    return this._httpClient.get(`${this.baseUrl}societe/deleteSousPrefecture/${id}`,{headers:this.getHeader()})
  }
  updateSousPrefecture(id,body):Observable<any>{
    return this._httpClient.put(`${this.baseUrl}societe/sousPrefecture/${id}`,body,{headers:this.getHeader()})
  }
  postSousPrefecture(body):Observable<any>{
    return this._httpClient.post(`${this.baseUrl}societe/createSousPrefecture`,body, {headers:this.getHeader()})
  }

  //Centre de formation
  postCentreFormation(body):Observable<any>{
    return this._httpClient.post(`${this.baseUrl}centreFormation/add`,body,{headers:this.getHeader()})
  } 
  updateCentreFormation(centre_id, body):Observable<any>{
    return this._httpClient.put(`${this.baseUrl}centreFormation/update/${centre_id}`,body,{headers:this.getHeader()})
  }
  deleteCentreFormation(centre_id):Observable<any>{
    return this._httpClient.delete(`${this.baseUrl}centreFormation/delete/${centre_id}`,{headers:this.getHeader()})
  }
  getCentreFormation():Observable<any>{
    return this._httpClient.get(`${this.baseUrl}centreFormation/get`,{headers:this.getHeader()})
  }

  //Type d'entreprise
  getTypeEntreprise():Observable<any>{
    return this._httpClient.get(`${this.baseUrl}entreprise/type-entreprise/`,{headers:this.getHeader()})
  }

  getTypeEntrepriseById(id):Observable<any>{
    return this._httpClient.get(`${this.baseUrl}entreprise/type-entreprise/get/${id}`, {headers:this.getHeader()})
  }
  
  postTypeEntreprise(body):Observable<any>{
    return this._httpClient.post(`${this.baseUrl}entreprise/type-entreprise/`,body,{headers:this.getHeader()})
  } 
  updateTypeEntreprise(id, body):Observable<any>{
    return this._httpClient.put(`${this.baseUrl}entreprise/type-entreprise/get/${id}`,body,{headers:this.getHeader()})
  }
  deleteTypeEntreprise(centre_id):Observable<any>{
    return this._httpClient.delete(`${this.baseUrl}entreprise/type-entreprise/get/${centre_id}`,{headers:this.getHeader()})
  }
 
}
