import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';

import { AuthGuardService } from './pages/auth-guard.service';

// StatistiqueModule
export const AppRoutes: Routes = [{
            path: '',
            redirectTo: 'dashboard',
            pathMatch: 'full',
        },
        {
        path: '',
        component: AdminLayoutComponent,
        children: [{
            path: '',
            loadChildren: './dashboard/dashboard.module#DashboardModule',
            canActivate: [AuthGuardService],
            canActivateChild: [AuthGuardService],
        },
        {
            path: 'entreprises',
            loadChildren: './entreprises/entreprises.module#EntreprisesModule',
            canActivate: [AuthGuardService],
            canActivateChild: [AuthGuardService],
        },
        {
            path: 'centres',
            loadChildren: './centres/centres.module#CentresModule',
            canActivate: [AuthGuardService],
            canActivateChild: [AuthGuardService],
        },
        {
            path: 'agrements',
            loadChildren: './agrements/agrements.module#AgrementsModule',
            canActivate: [AuthGuardService],
            canActivateChild: [AuthGuardService],
        },
        {
            path: 'users',
            loadChildren: './users/users.module#UsersModule',
            canActivate: [AuthGuardService],
            canActivateChild: [AuthGuardService],
        },
        {
            path: 'tracks',
            canActivate: [AuthGuardService],
            canActivateChild: [AuthGuardService],
            loadChildren: './users/users.module#UsersModule'
        },{
            path: 'user',
            loadChildren: './userpage/user.module#UserModule',
            canActivate: [AuthGuardService],
            canActivateChild: [AuthGuardService],
        },
        {
            path:'configs',
            loadChildren: './configs/configs.module#ConfigsModule',
            canActivate: [AuthGuardService],
            canActivateChild: [AuthGuardService],
        },
        {
            path:'agents',
            loadChildren: './agents/agents.module#AgentsModule',
            canActivate: [AuthGuardService],
            canActivateChild: [AuthGuardService],
        },
        {
            path:'registres',
            loadChildren: './registres/registres.module#RegistresModule',
            canActivate: [AuthGuardService],
            canActivateChild: [AuthGuardService],
        }
        /*
        * Dossiers all module
        */
       
    ]
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [{
            path: 'pages',
            loadChildren: './pages/pages.module#PagesModule'
        }]
    },
     
    {
        path: '**',
        redirectTo: 'dashboard',
    },
];
