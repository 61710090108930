import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTabsModule } from '@angular/material/tabs';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxPaginationModule } from 'ngx-pagination';

import { RouterModule } from '@angular/router';
import { AgrementRoutes } from './agrements.routing';
import { DemandeAgrementComponent } from './demande-agrement/demande-agrement.component';
import { InfoAgrementComponent } from './info-agrement/info-agrement.component';
import { ListAgrementComponent } from './list-agrement/list-agrement.component';


@NgModule({
  declarations: [
    ListAgrementComponent,
    DemandeAgrementComponent,
    InfoAgrementComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(AgrementRoutes),
    FormsModule,
    ReactiveFormsModule,
    AngularMyDatePickerModule,
    MatPaginatorModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    MatDialogModule,
    PdfViewerModule,
    MatTabsModule,
  ]
})
export class AgrementsModule { }
