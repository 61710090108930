import { logo,filligrane, oraspc, branding, drapeau } from 'app/logo_Muhat';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from '../font/vfs_fonts.js';

export default function generateAgrement(entreprise: any) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.fonts = {
        calibri: {
            normal: 'regular.otf',
            bold: "bold.otf",
            italics: "italic.otf",
            bolditalics: 'bold-italic.otf'
        },
        arial: {
            normal: 'arial.ttf',
            bold: 'Arial-Bold.ttf',
            italics: 'Arial-Italic.ttf',
            bolditalics: 'Arial-Bold-Italic.ttf'
        },
    }

    const documentDefinition = {
        pageOrientation: 'portrait',
        pageMargins: [40, 15, 40, 15],
        info: {
            title: 'Agrément',
            author: 'ANDE',
            subject: 'Agrément',
        },
        
        background: function(currentPage, pageSize) {
            return {image: filligrane, width: 300, absolutePosition: {x: 150, y: 250}, opacity: 0.2}
        }, 
        content: [
            
            {
                image: oraspc,
                width: 70,
                height: 80,
                alignment:'center',     
            },

            {
                text:  'Republique de guinée'.toUpperCase() , 
                fontSize: 12, bold:true, 
                alignment:'left', margin: [0, -60, 0, 0]
            },
            
            {text: 'Travail', alignment:'left', color: "#FF0000", margin: [10, 0, 0, 0]},
            {text: '-', margin: [45, -12, 0, 0]},
            {text: 'Justice',  color: "#FFFF00", margin: [50, -12, 0, 0]},
            {text: '-', margin: [85, -12, 0, 0]},
            {text: 'Solidarité', color: "#00FF00", margin: [90, -12, 0, 0]},
            
            {
                text:  'Ministère de la sécurité et \nde la protection civile'.toUpperCase(), 
                fontSize: 12, alignment:'right', bold:true, 
                margin: [0, -30, 0, 0]
            },
            
            {
                text: "office de regulation des agences de sécurité\net de la protection civile".toUpperCase(),
                alignment:'center', bold:true,
                fontSize:13, margin:[0, 65, 0, 0]
            },

            {
                text: "                                   ",
                alignment:'center', bold:true,
                decoration: "underline", margin:[0, 5, 0, 0]
            },

            {
                style: 'header',
                margin:[180, 40, 0, 40],
                table: {
                    headerRows: 1,
                    widths: [180],
                    body: [
                        [
                            {
                                border: [false, false, false, false],
                                fillColor: '#0A273B',
                                color: "#ffffff",
                                text: "Agrément".toUpperCase(),
                                characterSpacing: 10,
                                fontSize:18, alignment:'center',
                            }
                        ]
                    ]
                }
            },

            {
                text: "DECISION N° D___/ MSPC / ORASPC", 
                fontSize:14, alignment:'center', margin:[0, 10, 0, 0]
            },

            {
                text: "portant la delivrance de l'autorisation administrative de fonction pour \n".toUpperCase() +
                "les sociétés et entreprises de sécurité privéé  civile en république de guinée".toUpperCase(), 
                fontSize:12, alignment:'center', margin:[0, 10, 0, 0],
            },
                
            {
                text: "Le directeur général".toUpperCase(), 
                fontSize:14, bold: true, alignment:'center', margin:[0, 25, 0, 0]
            },

            {
                text: "VU:", decoration: "underline",
                fontSize:14, bold: true, margin:[0, 10, 0, 0]
            },

            {
                ul: [
                    'La charte de la transition',
                    'Le décret N° D/2020/216/PRG/SGG du 26 août 2020 protant création et reglementation des activités des entreprises ou sociétés de sécurité privée',
                    'Le décret N° D/2021/163/PRG/SGG du 26 mai portant attribution et organisation du ministère de la sécurité et de la protection civile',
                    'Le communiqué N° 1 du Comité National de Rassemblement pour le Développement CNRD du 05 septembre 2021 portant prise effective du pouvoir par les Forces de Défense et de Sécurité'
                ],
                fontSize:10,
            },

            {
                text: "DECIDE", alignment:'center',
                fontSize:14, bold: true, margin:[0, 25, 0, 0]
            },

            {
                text: `Une autorisation d'exercer comportant le numéro ${entreprise.numero_agreement} est délivré `+
                `à ${entreprise.nom}, de numéro ${entreprise.rccm}.`, alignment:'left',
                margin:[0, 20, 0, 0], fontSize:10,
            },

            {
                text: "Elle autorise son titulaire à exercer la ou les activités suivantes:", alignment:'left',
                margin:[0, 8, 0, 5], fontSize:10,
            },

            {
                ul: [
                    'Transport de fond',
                ]
            },

            {
                text: "Le directeur général de l'ORASPC", alignment:'right',
                bold: true, fontSize:12,
                margin:[0, 60, 0, 0]
            },

            {
                text: "Moussa DIAWARA", alignment:'right',
                bold: true, fontSize:12,
                margin:[0, 50, 0, 0]
            }
        ],
        footer: {
            columns: [
                {
                    image: drapeau,
                    width: 40,
                    height: 20,
                    margin:[40, 0, 0, 0]
                },
    
                {
                    image: branding,
                    width: 70,
                    height: 30,
                    margin:[50, -17, 0, 0]
                },
                {
                    qr: 'ORASPC',
                    height: 30,
                    width: 30,
                    margin:[350, -100, 0, 0]
                },
            ]
        },
        styles: {
            footer: {
                fontSize: 14,
            },
            title: {
                fontSize: 14,
                alignment: 'center',
            },
            title_right: {
                fontSize: 14,
                alignment: 'right',
            },
            title_underline: {
                fontSize: 14,
                alignment: 'center',
                decoration: 'underline'
            },
            title_right_underline_justify: {
                fontSize: 14,
                alignment: 'right',
                decoration: 'underline'
            },
            objet_underline:{
                decoration: 'underline',
                margin: [0, 0, 0, 0]
            },
            content: {
                fontSize: 16,
                lineHeight:2.2
            },
            list: {
                fontSize: 14,
                margin: [20, 0, 0, 0]
            }
            ,
            experienceStyle: {
                margin: [0, 0, 0, 10]
            },
            
        },
        defaultStyle: {
            color: '#000',
            font: 'calibri'
        }
    };

    return pdfMake.createPdf(documentDefinition);
}
