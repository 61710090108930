import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EntrepriseService } from 'app/entreprises/entreprise.service';
import { GlobalConstants } from 'app/global-constants';

@Component({
  selector: 'app-affectation-agence',
  templateUrl: './affectation-agence.component.html',
  styleUrls: ['./affectation-agence.component.css']
})
export class AffectationAgenceComponent implements OnInit {
  recherche: any = "";
  agences = [];
  allAgences = [];
  idAgent: number;

  constructor(private dialogRef: MatDialogRef<AffectationAgenceComponent>,
    private agenceService: EntrepriseService,
    @Inject(MAT_DIALOG_DATA) data) { 
      this.idAgent = data.id
    }

  ngOnInit(): void {
    this.dialogRef.updateSize('50%', '50%');
    this.getAgences();
  }

  getAgences() {
    this.agences = [];
    this.agenceService.getEntreprises(this.recherche).subscribe(
      (reponse) => {
        this.agences = reponse.data;
        this.allAgences = reponse.data;
      }
    )
  }

  affectation(idAgence:number) {
    
  }

  onKeyUp(e) {
    let copie = this.allAgences.filter((agence) => agence.telephone==this.recherche ||
     agence.email==this.recherche || agence.nom.search(new RegExp(`${this.recherche}`, 'i')) != -1)
     this.agences = copie;
  }

  close(updated) {
    this.dialogRef.close(updated);
  }

  fermer() {
    this.close(false)
  }

}
