import { LoadingService } from './loading.service';
import {delay} from 'rxjs/operators';
import { Component, AfterViewInit, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit, AfterViewInit {
  
  title = 'ORASPC';
  loading: boolean = false;
  
  constructor(private _loading: LoadingService){}
  
  ngOnInit(){
    this.listenToLoading();
  }

  /**
   * Listen to the loadingSub property in the LoadingService class. This drives the
   * display of the loading spinner.
   */
  listenToLoading(): void {
    this._loading.loadingSub.pipe(delay(0)).subscribe((loading) => {
      this.loading = loading;
    });
  }

  
  ngAfterViewInit(){
    var $btn_style = $('.main-content')
    var $theme = localStorage.getItem('theme')
    $btn_style.attr('data-btn-style',$theme)
  }
}