
import { Routes } from "@angular/router";
import { AgentsListComponent } from "./agents-list/agents-list.component";
import { AgentEditComponent } from "./agent-edit/agent-edit.component";

export const ConfigsRoute: Routes = [
    {
        path: '',
        children: [
        {
            path: 'liste',
            component: AgentsListComponent
        },
        {
            path: 'ajouter',
            component: AgentEditComponent
        },

        {
            path: 'editer/:id',
            component: AgentEditComponent
        },
        
    ]
    }
];