import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListAgrementComponent } from './list-agrement/list-agrement.component';
import { DemandeAgrementComponent } from './demande-agrement/demande-agrement.component';
import { InfoAgrementComponent } from './info-agrement/info-agrement.component';

export const AgrementRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "agrements",
        component: ListAgrementComponent,
      },
      {
        path: 'add',
        component: DemandeAgrementComponent
      },
      {
        path: 'list',
        component: ListAgrementComponent
      },
      {
        path: 'edit/:id',
        component: DemandeAgrementComponent
      },
      {
        path: 'info/:id',
        component: InfoAgrementComponent
      },
    ],
  },
];
