import { Injectable} from '@angular/core';
import { CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { GlobalConstants } from 'app/global-constants';


@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild {
  	
  constructor(private router: Router,private route: ActivatedRoute,) {}

  //permissions
  view_dashbord = GlobalConstants.has_permission("view_dashbord")
  view_users = GlobalConstants.has_permission("view_users")
  view_societe = GlobalConstants.has_permission("view_societe")
  view_offre = GlobalConstants.has_permission("view_offre")

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.isAuthenticated()
    let url: string = state.url;
    if(localStorage.getItem("token") && this.isAuthenticated()) return true; 
    
    this.router.navigate([`pages/login`]);
  return false;
        
  }
  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let url: string = state.url;

    if(localStorage.getItem("token") && this.isAuthenticated()) return true; 
      
      this.router.navigate([`pages/login`]);
  return false;
        
  }  

  isAuthenticated() {
    if (this.view_societe || this.view_offre || this.view_users){
      return true;
    }
    return false;
  }
}


@Injectable()
export class AuthGuardChildService implements CanActivate {

  //permissions
  view_dashbord = GlobalConstants.has_permission("view_dashbord")
  view_users = GlobalConstants.has_permission("view_users")
  view_societe = GlobalConstants.has_permission("view_societe")
  view_offre = GlobalConstants.has_permission("view_offre")
  
  constructor(private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      let url: string = state.url;
      if(this.isAuthenticated()) return true; 
      this.router.navigate([`pages/login`],{ queryParams: { q: url } });
    return false;
  }

  isAuthenticated() {
    if (this.view_societe || this.view_offre || this.view_users){
      return true;
    }
    this.router.navigate(['dashboard']);
    return false;
  }
}