import { Component, OnInit, ViewChild} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AngularMyDatePickerDirective, IAngularMyDpOptions } from "angular-mydatepicker";
import { ConfigsService } from "app/configs/configs.service";
import { EntrepriseService } from "app/entreprises/entreprise.service";
import { GlobalConstants } from "app/global-constants";
import intlTelInput from "intl-tel-input";

@Component({
  selector: 'app-demande-agrement',
  templateUrl: './demande-agrement.component.html',
  styleUrls: ['./demande-agrement.component.css']
})
export class DemandeAgrementComponent implements OnInit {
  demandeForm: FormGroup;
  @ViewChild('dateDebut') mydpDateNaissance: AngularMyDatePickerDirective;
  myDatePickerOptions:IAngularMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    disableSince: {year: 0, month: 0, day: 0},
  };
  idDemande: number = 0;
  demande: any;
  typeEntreprises: [];
  isLoading: boolean = false;
  documents: any[] = [];
  files: any[] = [];
  file: File;
  isDocumentsRequiredUploaded: boolean = false;

  constructor(
    private router: Router,
    private entrepriseService: EntrepriseService,
    private configService: ConfigsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // this.initPhone();
    GlobalConstants.hasPermissionRedirection('create_demande_agrement',this.router)
    
    this.getAllTypeEntreprises();
    this.demandeForm = new FormGroup({
      activite:  new FormControl('', [Validators.required]),
      description: new FormControl('')
    });

    // this.getDemandeDetail();
  }

  getDemandeDetail() {
    let params = this.route.snapshot.params;
    if (Object.keys(params).includes("id")) {
      this.idDemande = params.id;
      this.entrepriseService
        .getDemandeById(this.idDemande)
        .subscribe((reponse) => {
          this.demande = reponse;
          // let entreprise = this.demande.entreprise === null ? "": this.demande.entreprise.id
          let activite = this.demande.activite === null ? "": this.demande.activite.id
          this.demandeForm.setValue({
            activite: activite,
            description: this.demande.description
          });
        });
    }
  }

  onSubmit($event) {
    $event.preventDefault();
    if(this.isLoading) {
      return;
    }

    const requiredDocuments = this.documents.filter(document => document.isRequired);
    const uploadedRequiredDocuments = this.files.filter(file => requiredDocuments.some(doc => doc.id === file.idDocument));
    this.isDocumentsRequiredUploaded = requiredDocuments.length === uploadedRequiredDocuments.length;

    if (!this.isDocumentsRequiredUploaded) {
      GlobalConstants.showNotification("Veuillez charger tous les documents obligatoires.", "warning");
      return;
    }

    this.isLoading = true;

    // this.demandeForm.value['documents'] = this.files;
    let data = { ...this.demandeForm.value };
    let id = this.demandeForm.get('activite').value
    let description = this.demandeForm.get('description').value
    if (this.idDemande === 0) {
      this.entrepriseService.postDemande(id,description, this.files).subscribe(
        (response) => {
          this.isLoading = false;
          if (response.status == "warning") {
            GlobalConstants.showNotification(
              "La demande existe déjà dans la base",
              "warning"
            );
          } else if (response.status == "success") {
            if (response["exist"]) {
              GlobalConstants.showNotification(response["exist"], "danger");
            } else {
              this.handleResponse(response);
            }
          }else if (response.status == "empty") {
            GlobalConstants.showNotification(
              "Les fichiers ne sont pas téleversé",
              "warning"
            );
          }
        },

        (err) => {
          this.isLoading = false;
          GlobalConstants.errorRequest(err)
        }
      ); 
    } else {
      this.entrepriseService
        .updateDemande(data, this.idDemande)
        .subscribe(
          (response) => {
            this.isLoading = false;
            if (response.status == "success") {
              GlobalConstants.showNotification(
                "Les informations ont été modifié avec succès",
                "success"
              );
              this.router.navigate(["/entreprises/demande-list"]);
            } else {
              GlobalConstants.showNotification(
                "Une erreur s'est produite lors de la modification des informations ",
                "error"
              );
            }
          },

          (err) => {
            this.isLoading = false;
            GlobalConstants.errorRequest(err)
          }
        );
    }
  }

  onActiviteChange($event){
    let id = $event.target.value;
    if(id){
      this.getDocuments(id);
    }else{
      this.documents = [];
    }
  }

  onFileSelected($event, id){
    const file: File = $event.target.files[0];
    this.files.push({
      idDocument: id,
      file: file
    })
  }

  getDocuments(id){
    this.configService.getDocuments(id).subscribe(
      (response) =>{
        console.log(response);
        this.documents = response
      },(error)=>{
        console.log(error);
      }
    )

  }

  cancel() {
    this.router.navigate(["/agrements/list"]);
  }

  handleResponse(response) {
    GlobalConstants.showNotification(
      "la demande a été enregistré avec succès.",
      "success");
    this.router.navigate(["/agrements/list"]);
  }

  getAllTypeEntreprises(){
    this.configService.getTypeEntreprise().subscribe(
      (response) => {
        this.typeEntreprises = response;
      }
    )
  }

  onChangeCapitalSocial(event) {
    let capital = event.target.value;
    capital = GlobalConstants.formatNombre(capital)
    this.demandeForm.setValue({...this.demandeForm.value, ...{montant: capital}})
  }

  has_permission(p){
    return GlobalConstants.has_permission(p)
  }

  is_admin(){
    return GlobalConstants.is_admin();
  }

  is_agence(){
    return GlobalConstants.is_agence();
  }

  formatDate(date){
    return GlobalConstants.formatDate(date);
  }
}
